// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-auth-callback-index-js": () => import("./../../../src/pages/auth-callback/index.js" /* webpackChunkName: "component---src-pages-auth-callback-index-js" */),
  "component---src-pages-cognitive-health-program-index-js": () => import("./../../../src/pages/cognitive-health-program/index.js" /* webpackChunkName: "component---src-pages-cognitive-health-program-index-js" */),
  "component---src-pages-contact-confirmation-js": () => import("./../../../src/pages/contact/confirmation.js" /* webpackChunkName: "component---src-pages-contact-confirmation-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-create-account-index-js": () => import("./../../../src/pages/create-account/index.js" /* webpackChunkName: "component---src-pages-create-account-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-lp-a-program-to-prevent-alzheimers-index-js": () => import("./../../../src/pages/lp/a-program-to-prevent-alzheimers/index.js" /* webpackChunkName: "component---src-pages-lp-a-program-to-prevent-alzheimers-index-js" */),
  "component---src-pages-lp-hope-in-the-fight-against-alzheimers-index-js": () => import("./../../../src/pages/lp/hope-in-the-fight-against-alzheimers/index.js" /* webpackChunkName: "component---src-pages-lp-hope-in-the-fight-against-alzheimers-index-js" */),
  "component---src-pages-meal-plans-index-js": () => import("./../../../src/pages/meal-plans/index.js" /* webpackChunkName: "component---src-pages-meal-plans-index-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-signup-index-js": () => import("./../../../src/pages/signup/index.js" /* webpackChunkName: "component---src-pages-signup-index-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-interior-page-js": () => import("./../../../src/templates/interior-page.js" /* webpackChunkName: "component---src-templates-interior-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

