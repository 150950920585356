import auth0 from "auth0-js";
import { navigate } from "gatsby";

export const isBrowser = typeof window !== "undefined";

export const tokens = {
  idToken: false,
  accessToken: false,
  expiresAt: false,
};

let user = {};

export const isAuthenticated = () => {
  if (!isBrowser) {
    return;
  }

  return localStorage.getItem("isLoggedIn") === "true";
};

// Only load Auth0 if we're in the browser vs gatsby build stage
const auth = isBrowser
  ? new auth0.WebAuth({
      domain: process.env.AUTH0_DOMAIN,
      clientID: process.env.AUTH0_CLIENTID,
      redirectUri:
        window.location.hostname === "localhost"
          ? "http://localhost:8888/auth-callback/"
          : "https://amosinstitute.com/auth-callback/",
      responseType: "token id_token",
      audience: process.env.AUTH0_API_ID,
      scope: "openid profile email user_metadata app_metadata",
    })
  : {};

// Managment API
export const updateUserMetadata = (userId, metadata) => {
  console.log("metadata--", metadata);

  const mgmtAuth = isBrowser
    ? new auth0.Management({
        domain: process.env.AUTH0_DOMAIN,
        token: tokens.accessToken,
      })
    : {};

  mgmtAuth.patchUserMetadata(userId, metadata, function(err, authResult) {
    if (err) {
      console.log(err);
    } else {
      // You can view the result with:
      // JSON.stringify(authResult)
      // Create a new event
      console.log("User Metadata Updated...");
      var event = new CustomEvent("user_metadata_updated");
      window.dispatchEvent(event);
    }
  });
};

export const login = (context) => {
  if (!isBrowser) {
    return;
  }

  if (context === "signUp") {
    auth.authorize({
      mode: "signUp",
    });
    return;
  }

  auth.authorize();
};

export const logout = () => {
  localStorage.setItem("isLoggedIn", false);

  auth.logout({
    returnTo: window.location.origin,
  });
};

const setSession = (cb = () => {}) => (err, authResult) => {
  if (err) {
    console.log(err);
    // navigate("/account/");
    logout();
    cb();
    return;
  }

  if (authResult && authResult.accessToken && authResult.idToken) {
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    tokens.accessToken = authResult.accessToken;
    tokens.idToken = authResult.idToken;
    tokens.expiresAt = expiresAt;

    user = authResult.idTokenPayload;

    if (
      localStorage.getItem("isLoggedIn") === null ||
      localStorage.getItem("isLoggedIn") === "false"
    ) {
      var loginEvent = new CustomEvent("new_session");
      window.dispatchEvent(loginEvent);
    }

    localStorage.setItem("isLoggedIn", true);

    if (window.location.pathname === "/auth-callback/") {
      navigate("/account/");
    }

    cb();
  }
};

export const checkSession = (callback) => {
  const isLoggedIn = window.localStorage.getItem("isLoggedIn");
  if (isLoggedIn === "false" || isLoggedIn === null) {
    callback();
  }
  const protectedRoutes = [`/account`];
  const isProtectedRoute = protectedRoutes
    .map((route) => window.location.pathname.includes(route))
    .some((route) => route);
  if (isProtectedRoute) {
    auth.checkSession({}, setSession(callback));
  }
};

export const handleAuthentication = () => {
  if (!isBrowser) {
    return;
  }

  auth.parseHash(setSession());
};

export const getProfile = async () => {
  return user;
};

export const silentAuth = (callback) => {
  if (!isAuthenticated()) return callback();
  auth.checkSession({}, setSession(callback));
};
